/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// [data-theme="light"] {
//   // --p: red;
//   --p: 70.625% 0.9064 49.28;
// }

:root {
  // sm, md, lg, xl
  --max-width: 100vw;

  // 内边距
  --sm-sp: 8px;
  --md-pad: 8px;
  --lg-pad: 10px;
  --xl-pad: 15px;

  // 列间距
  --sm-col-space: 20px;
}
/* 隐藏整个页面的滚动条 */
body::-webkit-scrollbar {
  display: none; /* Chrome, Safari 和 Opera */
}

body {
  -ms-overflow-style: none; /* IE 和 Edge */
  scrollbar-width: none; /* Firefox */
}

$num: 5;
$gap: 5px;
// 基础变量
$small-item-size: calc(
  calc(100vw - calc($gap * ($num - 1) + calc(var(--sm-sp) * 2))) / $num
);
$small-x-2: calc($small-item-size * 2 + $gap);
$small-x-3: calc($small-item-size * 3 + $gap * 2);
$small-x-4: calc($small-item-size * 4 + $gap * 3);
$small-x-5: calc($small-item-size * 5 + $gap * 4);

.m_cont {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax($small-item-size, 1fr));
  grid-template-columns: repeat($num, 1fr);
  grid-gap: $gap;
  padding: var(--sm-sp);
  position: relative;
  // height: calc($small-item-size * 3 + $gap * 2 + var(--sm-sp) * 2);
  grid-auto-rows: $small-item-size; // 设置行高与列宽一致
  grid-auto-columns: $small-item-size; // 设置行高与列宽一致
  // border: 1px solid rgba(0, 0, 0, 0.1);

  > .btn {
    border-radius: 3px;
    aspect-ratio: 1 / 1; // 确保元素是正方形
    width: $small-item-size;
    height: $small-item-size;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    transition: all 0.3s; // 添加动画效果时长
    // transition:none
    // opacity: 1;
    .btn_txt {
      // height: 100%;
      // white-space: normal; /* 允许换行 */
      // word-break: break-all; /* 强制在单词内换行 */
      // max-width: 70%; /* 设置最大宽度为按钮宽度的一半 */
      // text-align: center; /* 可选：居中文本 */
      // overflow: hidden;
      // width: 100%;
    }
    .bt_m {
      position: absolute;
      font-size: 0.8rem;
      font-weight: 500;
      bottom: 7px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  // .it0 {
  //   pointer-events: none;
  // }
  // // 第2级 横
  // .it1 {
  //   grid-column: span 2;
  //   grid-row: span 1;
  //   height: $small-item-size;
  //   width: $small-x-2;
  // }
  // // 第2级 竖
  // .it1 {
  //   grid-column: span 1;
  //   grid-row: span 2;
  //   height: $small-x-2;
  //   width: $small-item-size;
  // }
  // // 第3级 正方型
  // .it1 {
  //   grid-column: span 2;
  //   grid-row: span 2;
  //   width: $small-x-2;
  //   height: $small-x-2;
  // }

  // //第4级 竖
  // .it1 {
  //   grid-column: span 2;
  //   grid-row: span 3;
  //   width: $small-x-2;
  //   height: $small-x-3;
  // }
  // //第4级  横
  // .it1 {
  //   grid-column: span 3;
  //   grid-row: span 2;
  //   width: $small-x-3;
  //   height: $small-x-2;
  // }
  // // 第5级 正方形
  // .it1 {
  //   grid-column: span 3;
  //   grid-row: span 3;
  //   width: $small-x-3;
  //   height: $small-x-3;
  // }
}

/* 全局修改toast样式 */
.toast {
  padding: 3rem 1rem 1rem 1rem;
  /* animation: fadeOut 2s forwards; */
  opacity: 1; /* 初始透明度 */

  .alert-warning {
    border-color: var(--fallback-wa, oklch(var(--wa) / 0.2));
    --tw-text-opacity: 1;
    color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
    --alert-bg: var(--fallback-wa, oklch(var(--wa) / 1));
    --alert-bg-mix: var(--fallback-b1, oklch(var(--b1) / 1));
  }
  .alert-error {
    border-color: var(--fallback-er, oklch(var(--er) / 0.2));
    --tw-text-opacity: 1;
    color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
    --alert-bg: var(--fallback-er, oklch(var(--er) / 1));
    --alert-bg-mix: var(--fallback-b1, oklch(var(--b1) / 1));
  }
}

/* 全局修改 modal 样式 */
.modal {
  .mb-rerow {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
  }
}

// 修改分割线样式

.divider:before,
.divider:after {
  height: 0.5px;
}

.fg_base_pry {
  background-color: oklch(var(--b1));
}
.bg_base_pry {
  background-color: oklch(var(--b2));
}
